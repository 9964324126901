<template>
    <div class='page'>
        <div class="body1">
            <img src="@/assets/images/index1.png" alt="">
            <!-- <div class="fixed-header-right dis_flex items_center" v-if="!topShow">
                <div class="apply flex_btn" @click="openTry" v-if="$i18n.locale == 'zh'">{{ $t('nav.value6') }}</div>
                <div class="lang flex_btn" @click="changeLang">{{ $i18n.locale == 'en' ? '中' : 'EN' }}</div>
            </div> -->
            <div class="info wow fadeInDown">
                <div class="title dis_flex items_center">
                    {{ $t('index.value1') }}
                    <div class="line"></div>
                </div>
                <div class="subtitle">{{ $t('index.value2') }}</div>
                <div class="desc">{{ $t('index.value3') }}</div>
            </div>
        </div>
        <div class="body2">
            <img class="index3" src="@/assets/images/index3.png" alt="">
            <img src="@/assets/images/index3.gif" alt="" class="gif wow fadeInLeft">
            <div class="title dis_flex items_center  wow fadeInDown">
                <img src="@/assets/images/index2.png" alt="">
                {{ $t('index.value14') }}
            </div>
            <div class="swiper wow fadeInDown">
                <swiper :options="swiperOption" ref="mySwiper">
                    <swiper-slide v-for="(item, index) in swipers" :key="index">
                        <div class="swiper-title dis_flex">
                            <img :src="item.img" alt="">
                            {{ item.title }}
                        </div>
                        <div class="swiper-desc">{{ item.desc }}</div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
        <div class="body3">
            <div class="title dis_flex items_center wow fadeInDown">
                <img src="@/assets/images/index2.png" alt="">
                {{ $t('index.value15') }}
            </div>
            <div class="main-tit txt_center wow fadeInDown">
                <img class="index5" src="@/assets/images/index5.png" alt="">
                <img class="index4" src="@/assets/images/index4.png" alt="">
                <!-- <div class="title1">{{ $t('index.value10') }}</div> -->
                <div class="title2">{{ $t('index.value11') }}</div>
                <div class="title3">{{ $t('index.value12') }}</div>
            </div>
            <div class="desc txt_center wow fadeInDown">
                {{ $t('index.value13') }}
            </div>
            <img class="index6 wow fadeInDown" src="@/assets/images/index6.png" alt="">
        </div>
        <Pop ref="pop">
            <div class="try">
                <div class="title txt_center">{{ questionnaireInfo.surveyName }}</div>
                <div class="desc txt_center" v-html="questionnaireInfo.surveyDescription"></div>
                <div class="form">
                    <div class="form-item" v-for="(item, index) in questionnaireProblem" :key="item.id">
                        <div class="label">{{ index + 1 + '、' + item.questionName }}</div>
                        <div class="value input" v-if="item.choices.type == 'text' && index != 3">
                            <input type="text" v-model="item.value">
                        </div>
                        <div class="value" v-if="item.choices.type == 'single'">
                            <el-radio-group v-model="item.value">
                                <el-radio v-for="(v, i) in item.choices.options" :key="i" :label="v.optionValue">{{ v.optionValue
                                }}</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="value input" v-if="index == 3">
                            <el-date-picker v-model="item.value" value-format="yyyy-MM-dd" type="date"
                                :clearable="false" placeholder="">
                            </el-date-picker>
                            <img src="@/assets/images/date.png" alt="">
                        </div>
                    </div>

                </div>
                <div @click="submit" class="flex_btn">立即申请</div>
            </div>
        </Pop>
    </div>
</template>

<script>
// 引入插件
import { questionnaireDetail, questionnaireProblem, submitQuestionnaire } from '@/api/index'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { mapGetters } from "vuex"
import Pop from "@/components/pop.vue"
export default {
    components: {
        swiper,
        swiperSlide,
        Pop
    },
    data() {
        return {
            questionnaireInfo: {},
            questionnaireProblem: [],
            swiperOption: {
                grabCursor: true,
                // loop: true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true //允许分页点击跳转
                },
            },
            form: {
                value1: '',
                value2: '',
                value3: '',
                value4: '',
                value5: '',
                value6: '',
                value7: '',
                value8: '',
                value9: '',
                value10: '',
                value11: '',
                value12: '',
                value13: '',
                value14: '',
                value15: '',
                value16: '',
                value17: '',
                value17: '',
                value17: '',
                value17: '',
            }
        }
    },
    watch: {
        // topShow(val){
        //     this.$nextTick(()=>{
        //         this.$refs.mySwiper.swiper.loopDestroy(true, true);
        //         this.$refs.mySwiper.swiper.loopCreate();
        //     })
        // }
    },
    computed: {
        ...mapGetters(['topShow']),
        swipers() {
            return [{
                img: require('@/assets/images/index3-1.png'),
                title: this.$t('index.value4'),
                desc: this.$t('index.value5')
            }, {
                img: require('@/assets/images/index3-2.png'),
                title: this.$t('index.value6'),
                desc: this.$t('index.value7')
            }, {
                img: require('@/assets/images/index3-3.png'),
                title: this.$t('index.value8'),
                desc: this.$t('index.value9')
            }]
        }
    },
    created() {
        this.$nextTick(() => {   //页面渲染完，在执行
            new this.$wow({
                live: false
            }).init()
        })
        // this.getQuestionnaireDetail()
        // this.getQuestionnaireProblem()
    },
    methods: {
        async submit() {
            try {
                const requestData = {
                    surveyId: this.questionnaireInfo.id,
                    answers: [],
                    tel: this.form.value1,
                }
                this.questionnaireProblem.forEach((item, index) => {
                    requestData.answers.push({
                        questionName: item.questionName,
                        questionId: item.id,
                        answerType: item.choices.type,
                        value: item.value,
                        answerType: 1,
                    })
                })
                let res = await submitQuestionnaire(requestData)
                if(res.code == 0){
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    this.$refs.pop.dialogVisible = false
                    this.form = {
                        value1: '',
                        value2: '',
                        value3: '',
                        value4: '',
                        value5: '',
                        value6: '',
                        value7: '',
                        value8: '',
                        value9: '',
                        value10: '',
                        value11: '',
                        value12: '',
                        value13: '',
                        value14: '',
                        value15: '',
                        value16: '',
                        value17: '',
                    }
                }
            } catch (err) {
                console.log(err);

            }
        },
        async getQuestionnaireDetail() {
            try {
                const res = await questionnaireDetail({ id: process.env.NODE_ENV == 'development' ? 147 : 151 })//TODO 暂时写死,没有后台配置
                this.questionnaireInfo = res.data
            } catch (err) {
                console.log(err);
            }
        },
        async getQuestionnaireProblem() {
            const res = await questionnaireProblem({ id: process.env.NODE_ENV == 'development' ? 147 : 151 })//TODO 暂时写死,没有后台配置
            this.questionnaireProblem = res.data
            this.questionnaireProblem.forEach((item, index) => {
                item.choices = JSON.parse(item.choices)
                this.$set(item, 'value', null)
                console.log(item.choices);
            })
        },
        openTry() {
            this.$refs.pop.dialogVisible = true
        },
        // 切换语言
        changeLang() {
            if (this.$i18n.locale == 'zh') {
                this.$i18n.locale = 'en'
                localStorage.setItem('lang', 'en')
            } else {
                this.$i18n.locale = 'zh'
                localStorage.setItem('lang', 'zh')
            }
            this.$forceUpdate()
        }
    },
}
</script>
<style scoped lang='scss'>
.body1 {
    padding: 300px 164px 106px;
    box-sizing: border-box;
    position: relative;
    height: 1075px;

    img {
        position: absolute;
        top: 0;
        right: 0;
        // width: calc(100% - 750px);
        height: 100%;
        object-fit: cover;
        // z-index: 5;
    }

    .fixed-header-right {
        position: absolute;
        top: 32px;
        right: 50px;
        z-index: 10;

        .apply {
            width: 194px;
            height: 65px;
            border-radius: 33px;
            background: #00239C;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            margin-right: 20px;
            cursor: pointer;
        }

        .lang {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background: #fff;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .info {
        width: 600px;
        position: relative;
        z-index: 6;
    }

    .title {
        font-size: 65px;
        font-weight: bold;

        .line {
            width: 320px;
            height: 1px;
            background: #000;
            margin-left: 26px;
        }
    }

    .subtitle {
        font-size: 36px;
        font-weight: bold;
        margin: 26px 0 34px;
    }

    .desc {
        font-size: 20px;
        color: #7D7D7D;
    }
}

.body2 {
    width: 100%;
    height: 1060px;
    position: relative;

    .index3 {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .gif {
        position: absolute;
        bottom: 0;
        left: 26px;
        width: 50%;
    }

    .title {
        position: absolute;
        left: 147px;
        top: 74px;
        color: #fff;
        font-size: 36px;
        font-weight: bold;

        img {
            width: 72px;
            height: 72px;
            margin-right: 8px;
        }
    }

    .swiper {
        position: absolute;
        right: 170px;
        bottom: 184px;
        width: 580px;

        .swiper-pagination {
            text-align: right;
        }

        ::v-deep .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
            background: rgba($color: #fff, $alpha: .3);
            margin: 0 7px;
            opacity: unset;
        }

        ::v-deep .swiper-pagination-bullet-active {
            background: #fff;
            opacity: unset;
        }

        :v-deep .swiper-wrapper {
            align-items: center;
        }

        .swiper-container-horizontal>.swiper-pagination-bullets {
            bottom: 0;
        }

        .swiper-title {
            img {
                width: 128px;
                height: 128px;
            }

            font-size: 48px;
            font-weight: bold;
            margin-bottom: 26px;
            line-height: 1.2;
            color: #fff;
        }

        .swiper-desc {
            font-size: 20px;
            font-weight: bold;
            color: #7D7D7D;
            line-height: 1.8;
            color: #fff;
        }
    }
}

.body3 {
    padding: 80px 134px;
    box-sizing: border-box;

    .title {
        color: #00239B;
        font-size: 36px;
        font-weight: bold;

        img {
            width: 72px;
            height: 72px;
            margin-right: 8px;
        }
    }

    .main-tit {
        position: relative;
        font-weight: bold;
        line-height: 1.2;
        width: fit-content;
        margin: 100px auto;

        .index5,
        .index4 {
            position: absolute;
            width: 80px;
            height: 80px;
        }

        .index5 {
            left: -74px;
            top: -50px;
        }

        .index4 {
            right: -88px;
            bottom: -67px;
        }

        .title1 {
            font-size: 40px;
        }

        .title2 {
            font-size: 54px;
            margin: 10px 0 13px;
        }

        .title3 {
            font-size: 30px;
        }
    }

    .desc {
        width: 60%;
        color: #7D7D7D;
        font-size: 25px;
        margin: 0 auto 62px;
    }

    .index6 {
        width: 100%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
    .body2 {
        height: 900px;

        .swiper {
            .swiper-title {
                font-size: 40px;
            }

            .swiper-desc {
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .body2 {
        height: 750px;

        .swiper {
            .swiper-title {
                font-size: 32px;
            }

            .swiper-desc {
                font-size: 14px;
            }
        }
    }
}

.try {
    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;
        line-height: 1.2;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .desc {
        width: 80%;
        color: #5D5D5D;
        font-size: 12px;
        margin: 0 auto;
    }

    .form {
        height: 350px;
        overflow: auto;
        padding: 0 26px;
        box-sizing: border-box;

        .form-item {
            padding-top: 40px;

            &:last-child {
                padding-bottom: 40px;
            }

            .label {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.2;
            }

            .value {
                margin-top: 6px;
                position: relative;

                &.input {
                    height: 50px;
                    border-bottom: 1px solid #000000;
                }

                input {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .el-radio-group {
                    display: flex;
                    flex-direction: column;

                    .el-radio {
                        margin-top: 5px;

                        ::v-deep .el-radio__inner {
                            width: 18px;
                            height: 18px;
                        }

                        ::v-deep .el-radio__label {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .flex_btn {
        width: 198px;
        height: 54px;
        background: #00239C;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin: 20px auto 0;
        cursor: pointer;
    }
}

::v-deep .el-date-editor.el-input {
    width: 100%;
}

::v-deep .el-input__inner {
    border: none;
    padding-left: 0 !important;
}

::v-deep .el-input__prefix {
    display: none;
}

input {
    border: 0;
    /*清除自带的2px的边框*/
    padding: 0;
    /*清除自带的padding间距*/
    outline: none;
    /*清除input点击之后的黑色边框*/
}

::-webkit-scrollbar {
    background-color: #ECECEC;
    border-radius: 4px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #00239C;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #00239C;
}
</style>
